import AuthLayout from "@/components/layout/auth";
import { PageProps } from "@/types";
import { Head, Link } from "@inertiajs/react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ImageIcon, PlayCircleIcon, TrendingUpIcon, StarIcon } from "lucide-react";

const Dashboard = ({ auth }: PageProps) => {
    return (
        <>
            <Head title="Dashboard" />
            <div className="p-4 sm:p-6 max-w-7xl mx-auto space-y-6 sm:space-y-8">
                {/* Welcome Section */}
                <div className="space-y-2">
                    <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Welcome back{auth?.user?.name ? `, ${auth.user.name}` : ''}! 👋</h1>
                    <p className="text-muted-foreground">
                        What would you like to analyze today?
                    </p>
                </div>

                {/* Quick Actions */}
                <div className="grid gap-4 sm:grid-cols-2">
                    <Link href="/image_analysis">
                        <Card className="hover:bg-muted/50 transition-colors cursor-pointer h-full">
                            <CardHeader>
                                <CardTitle className="flex items-center gap-2 text-lg sm:text-xl">
                                    <ImageIcon className="w-5 h-5 text-primary" />
                                    Image Analysis
                                </CardTitle>
                                <CardDescription>
                                    Upload images to detect and analyze brand presence
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3">
                                    <Button variant="secondary" size="sm">
                                        Start Analysis
                                    </Button>
                                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                                        <StarIcon className="w-4 h-4" />
                                        Instant Results
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Link>

                    <Link href="/video_analysis">
                        <Card className="hover:bg-muted/50 transition-colors cursor-pointer h-full">
                            <CardHeader>
                                <CardTitle className="flex items-center gap-2 text-lg sm:text-xl">
                                    <PlayCircleIcon className="w-5 h-5 text-primary" />
                                    Video Analysis
                                </CardTitle>
                                <CardDescription>
                                    Analyze videos from YouTube, TikTok, and more
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3">
                                    <Button variant="secondary" size="sm">
                                        Start Analysis
                                    </Button>
                                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                                        <TrendingUpIcon className="w-4 h-4" />
                                        Frame by Frame
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Link>
                </div>

                {/* Features Overview */}
                <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <Card className="h-full">
                        <CardHeader>
                            <CardTitle className="text-base sm:text-lg">Brand Detection</CardTitle>
                            <CardDescription>
                                Advanced AI-powered brand recognition with high accuracy
                            </CardDescription>
                        </CardHeader>
                    </Card>

                    <Card className="h-full">
                        <CardHeader>
                            <CardTitle className="text-base sm:text-lg">Confidence Scoring</CardTitle>
                            <CardDescription>
                                Detailed confidence metrics for each detected brand
                            </CardDescription>
                        </CardHeader>
                    </Card>

                    <Card className="h-full">
                        <CardHeader>
                            <CardTitle className="text-base sm:text-lg">Location Mapping</CardTitle>
                            <CardDescription>
                                Precise brand location coordinates in media
                            </CardDescription>
                        </CardHeader>
                    </Card>
                </div>
            </div>
        </>
    )
}

Dashboard.layout = (page: React.ReactNode) => <AuthLayout>{page}</AuthLayout>

export default Dashboard;