import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Shield } from "lucide-react";
import { useForm } from "@inertiajs/react";
import { Head, Link } from "@inertiajs/react";
import { PageProps } from "@/types";

interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

interface LoginErrors {
  email?: string[];
  password?: string[];
}

const Login = ({ auth }: PageProps) => {
  const { data, setData, post, processing, errors } = useForm<{
    user: LoginForm;
  }>({
    user: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const formErrors = errors.user as LoginErrors | undefined;

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    post("/login", {
      preserveState: true,
      preserveScroll: true,
    });
  }

  return (
    <>
      <Head title="Login" />
      <div className="min-h-screen flex items-center justify-center bg-background px-4">
        <Card className="w-full max-w-md">
          <CardHeader className="space-y-1 flex flex-col items-center">
            <div className="flex items-center space-x-2">
              <Shield className="h-6 w-6 text-primary" />
              <span className="text-xl font-bold">VisionPro</span>
            </div>
            <CardTitle className="text-2xl">Welcome back</CardTitle>
            <CardDescription>
              Enter your credentials to access your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={data.user.email}
                  onChange={(e) => setData("user", { ...data.user, email: e.target.value })}
                  required
                />
                {formErrors?.email && (
                  <p className="text-sm text-destructive">{formErrors.email}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={data.user.password}
                  onChange={(e) => setData("user", { ...data.user, password: e.target.value })}
                  required
                />
                {formErrors?.password && (
                  <p className="text-sm text-destructive">{formErrors.password}</p>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="remember"
                  checked={data.user.remember}
                  onChange={(e) => setData("user", { ...data.user, remember: e.target.checked })}
                  className="rounded border-gray-300"
                />
                <Label htmlFor="remember">Remember me</Label>
              </div>
              <Button type="submit" className="w-full" disabled={processing}>
                Sign in
              </Button>
            </form>
            {/* <div className="mt-4 text-center text-sm">
              <Link href="/users/password/new" className="text-primary hover:underline">
                Forgot your password?
              </Link>
            </div> */}
            <div className="mt-4 text-center text-sm">
              Don't have an account?{" "}
              <Link href="/register" className="text-primary hover:underline">
                Sign up
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
} 

export default Login;
