import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Dialog, DialogContent, DialogTrigger, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";
import { Check, Loader2, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";
import AuthLayout from "@/components/layout/auth";
import { Head, useForm } from "@inertiajs/react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { usePoll } from '@inertiajs/react'

interface Brand {
  name: string;
  description?: string;
  confidence: number;
  location: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface FrameAnalysis {
  frame: string;
  timestamp: number;
  analysis: {
    brands: Brand[];
  };
}

interface Analysis {
  id: number;
  job_id: string;
  status: string;
  video_url: string;
  result: {
    frames_count: number;
    frames_analysis: FrameAnalysis[];
  };
  created_at: string;
  frames_urls: string[];
  total_frames: number;
  processed_frames: number;
  partial_results?: {
    frames_count: number;
    frames_analysis: FrameAnalysis[];
  };
}

interface Props {
  recent_analyses?: Analysis[];
  error?: string;
}

function FramePreview({ frameUrl }: { frameUrl: string }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="relative w-20 h-20 rounded-md overflow-hidden cursor-pointer hover:opacity-80 transition-opacity">
          <img
            src={frameUrl}
            alt="Frame preview"
            className="object-cover w-full h-full"
          />
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <div className="relative aspect-video w-full">
          <img
            src={frameUrl}
            alt="Frame full view"
            className="object-contain rounded-md w-full h-full"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function ProgressBar({ value, max }: { value: number; max: number }) {
  const percentage = max > 0 ? Math.round((value / max) * 100) : 0;
  
  return (
    <div className="w-full bg-muted rounded-full h-2.5 dark:bg-gray-700">
      <div 
        className="bg-primary h-2.5 rounded-full transition-all duration-500"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
}

function AnalysisRow({ analysis }: { analysis: Analysis }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { delete: destroy } = useForm();

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
  };

  const confirmDelete = () => {
    destroy(`/video_analysis/${analysis.id}`);
    setShowDeleteDialog(false);
  };

  const getFramesData = () => {
    if (analysis.status === 'completed') {
      return analysis.result?.frames_analysis || [];
    }
    return analysis.partial_results?.frames_analysis || [];
  };

  const frames = getFramesData().sort((a, b) => a.timestamp - b.timestamp);
  const allBrands = frames.flatMap(frame => frame.analysis.brands || []);
  const uniqueBrands = Array.from(new Set(allBrands.map(b => b.name)))
    .map(name => {
      const instances = allBrands.filter(b => b.name === name);
      const avgConfidence = instances.reduce((sum, b) => sum + b.confidence, 0) / instances.length;
      return { name, confidence: avgConfidence };
    });

  return (
    <>
      <TableRow 
        className={cn(
          "group cursor-pointer hover:bg-muted/50 transition-colors",
          isExpanded && "bg-muted/50"
        )}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCell className="align-top">
          <div className="flex flex-col sm:flex-row items-start gap-2">
            {analysis.frames_urls[0] && (
              <FramePreview frameUrl={analysis.frames_urls[0]} />
            )}
            <div className="flex flex-col gap-1">
              <div className="text-sm font-medium truncate max-w-[200px]">
                {analysis.video_url}
              </div>
              <div className="text-xs text-muted-foreground group-hover:text-primary transition-colors">
                {isExpanded ? '↑ Less' : '↓ More'}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell className="align-top hidden sm:table-cell">
          <div className="flex flex-col gap-2">
            <div className={cn(
              "inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium",
              analysis.status === 'completed'
                ? "bg-green-500/10 text-green-600"
                : "bg-yellow-500/10 text-yellow-600"
            )}>
              {analysis.status === 'completed' ? (
                <>
                  <Check className="h-4 w-4" />
                  <span>Completed</span>
                </>
              ) : (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span className="animate-pulse">
                    Processing...
                  </span>
                  {analysis.total_frames > 0 && <span className="text-xs">
                    ({analysis.processed_frames}/{analysis.total_frames})
                  </span>
                  }
                </>
              )}
            </div>
            {analysis.status !== 'completed' && analysis.total_frames > 0 && (
              <ProgressBar 
                value={analysis.processed_frames} 
                max={analysis.total_frames} 
              />
            )}
          </div>
        </TableCell>
        <TableCell className="align-top">
          <div className="space-y-3">
            <div className="block sm:hidden">
              <div className="flex flex-col gap-2">
                <div className={cn(
                  "inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium",
                  analysis.status === 'completed'
                    ? "bg-green-500/10 text-green-600"
                    : "bg-yellow-500/10 text-yellow-600"
                )}>
                  {analysis.status === 'completed' ? (
                    <>
                      <Check className="h-4 w-4" />
                      <span>Completed</span>
                    </>
                  ) : (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span className="animate-pulse">
                        Processing...
                      </span>
                      {analysis.total_frames > 0 && <span className="text-xs">
                        ({analysis.processed_frames}/{analysis.total_frames})
                      </span>
                      }
                    </>
                  )}
                </div>
                {analysis.status !== 'completed' && analysis.total_frames > 0 && (
                  <ProgressBar 
                    value={analysis.processed_frames} 
                    max={analysis.total_frames} 
                  />
                )}
              </div>
            </div>
            {frames.length > 0 && (
              <div className="space-y-1">
                <div className="text-xs font-medium text-muted-foreground">
                  Brands Detected ({uniqueBrands.length})
                  {analysis.status !== 'completed' && (
                    <span className="text-xs text-muted-foreground ml-1">
                      (Partial Results)
                    </span>
                  )}
                </div>
                <div className="flex flex-wrap gap-1">
                  {uniqueBrands.map((brand, i) => (
                    <Badge 
                      key={i} 
                      variant="outline" 
                      className={cn(
                        "text-xs",
                        analysis.status !== 'completed' && "animate-pulse"
                      )}
                    >
                      {brand.name}
                    </Badge>
                  ))}
                </div>
              </div>
            )}
          </div>
        </TableCell>
        <TableCell className="align-top text-sm text-muted-foreground hidden sm:table-cell">
          <div className="flex items-center justify-between">
            <span>{new Date(analysis.created_at).toLocaleString()}</span>
            <Button
              variant="ghost"
              size="icon"
              className="opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDelete}
            >
              <Trash2 className="h-4 w-4 text-destructive" />
            </Button>
          </div>
        </TableCell>
      </TableRow>

      {isExpanded && (
        <TableRow className="bg-muted/30">
          <TableCell colSpan={4} className="p-4">
            <Card>
              <CardContent className="pt-6">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                      <h4 className="font-medium">Frame Analysis</h4>
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-muted-foreground">
                          {frames.length} frames analyzed
                        </span>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="text-destructive"
                          onClick={handleDelete}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {frames.map((frame, index) => (
                        <Card key={index} className="overflow-hidden">
                          <div className="aspect-video relative">
                            <img
                              src={analysis.frames_urls[index]}
                              alt={`Frame ${index + 1}`}
                              className="object-cover w-full h-full"
                            />
                          </div>
                          <CardContent className="p-4">
                            <div className="space-y-3">
                              <div className="flex justify-between items-start gap-2">
                                <div className="text-sm font-medium">
                                  Frame {index + 1} - {frame.timestamp.toFixed(2)}s
                                </div>
                                <Badge variant="secondary">
                                  {frame.analysis.brands.length} brands
                                </Badge>
                              </div>
                              {frame.analysis.brands.length > 0 ? (
                                <div className="space-y-3">
                                  {frame.analysis.brands.map((brand, i) => (
                                    <div key={i} className="space-y-2 p-3 rounded-lg bg-muted/50">
                                      <div className="flex justify-between items-start gap-2">
                                        <span className="font-medium text-sm">
                                          {brand.name}
                                        </span>
                                        <Badge variant="outline" className="text-xs">
                                          {(brand.confidence * 100).toFixed(0)}% confidence
                                        </Badge>
                                      </div>
                                      {brand.description && (
                                        <div className="text-sm text-muted-foreground">
                                          <p className="font-medium text-xs">Description:</p>
                                          <p>{brand.description}</p>
                                        </div>
                                      )}
                                      <div className="text-sm text-muted-foreground">
                                        <p className="font-medium text-xs">Location:</p>
                                        <div className="grid grid-cols-2 gap-1 text-xs">
                                          <div>X: {brand.location.x}</div>
                                          <div>Y: {brand.location.y}</div>
                                          <div>Width: {brand.location.width}</div>
                                          <div>Height: {brand.location.height}</div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="text-sm text-muted-foreground">
                                  No brands detected
                                </div>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </div>

                  <details className="text-sm">
                    <summary className="cursor-pointer text-muted-foreground hover:text-foreground">
                      View raw JSON
                    </summary>
                    <pre className="mt-2 whitespace-pre-wrap break-all bg-muted p-4 rounded-lg text-xs overflow-x-auto">
                      {JSON.stringify(analysis.result, null, 2)}
                    </pre>
                  </details>
                </div>
              </CardContent>
            </Card>
          </TableCell>
        </TableRow>
      )}

      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Delete Video Analysis</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this video analysis? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2 sm:gap-0">
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete();
              }}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function usePollAnalyses({ enabled }: { enabled: boolean }) {
  const { start, stop } = usePoll(2000);

  useEffect(() => {
    if (enabled) {
      start();
    } else {
      stop();
    }
  }, [enabled]);
}


function AnalysisTable({ analyses }: { analyses: Analysis[] }) {
  const hasPendingAnalyses = analyses.some(a => a.status !== 'completed');
  usePollAnalyses({ enabled: hasPendingAnalyses });

  return (
    <div className="rounded-md border overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Video</TableHead>
            <TableHead className="w-[200px] hidden sm:table-cell">Status</TableHead>
            <TableHead>Detected Brands</TableHead>
            <TableHead className="w-[200px] hidden sm:table-cell">Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {analyses.map((analysis) => (
            <AnalysisRow 
              key={analysis.id || analysis.job_id}
              analysis={analysis}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function VideoAnalysis({ recent_analyses = [], error }: Props) {
  const [url, setUrl] = useState("");
  const { post, processing, setData, data } = useForm({
    video: {
      url: ""
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setData("video", { url });
    post("/video_analysis", {
      preserveScroll: true,
      onSuccess: () => {
        setUrl("");
        toast.success("Video analysis started");
      },
    });
  };

  return (
    <>
      <Head title="Video Analysis" />
      <div className="p-4 sm:p-6 max-w-7xl mx-auto space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-xl sm:text-2xl font-bold">Video Analysis</h1>
        </div>

        <div className="grid gap-6">
          <Card>
            <CardContent className="pt-6">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-col sm:flex-row gap-4">
                  <Input
                    type="url"
                    placeholder="Enter YouTube, TikTok, or other video URL"
                    value={url}
                    onChange={(e) => {
                      const newUrl = e.target.value;
                      setUrl(newUrl);
                      setData("video", { url: newUrl });
                    }}
                    className="flex-1"
                    required
                  />
                  <Button type="submit" disabled={processing || !url}>
                    {processing ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Processing
                      </>
                    ) : (
                      "Analyze"
                    )}
                  </Button>
                </div>
                {error && (
                  <div className="text-sm text-destructive">
                    {error}
                  </div>
                )}
              </form>
            </CardContent>
          </Card>

          <AnalysisTable analyses={recent_analyses || []} />
        </div>
      </div>
    </>
  );
}

VideoAnalysis.layout = (page: React.ReactNode) => <AuthLayout>{page}</AuthLayout>;

export default VideoAnalysis; 