import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";
import { Check, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import AuthLayout from "@/components/layout/auth";
import { Head } from "@inertiajs/react";
import { FileUploader } from "@/components/FileUploader";
import { usePoll } from '@inertiajs/react'

interface Brand {
  name: string;
  confidence: number;
  description?: string;
  location: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface Analysis {
  id: number;
  job_id: string;
  status: string;
  result: {
    brands: Brand[];
  };
  created_at: string;
  image_url: string;
}

interface Props {
  recent_analyses: Analysis[];
  job_ids?: string[];
}

function ImagePreview({ imageUrl, previewImageUrl }: { imageUrl: string, previewImageUrl: string }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="relative w-20 h-20 rounded-md overflow-hidden cursor-pointer hover:opacity-80 transition-opacity">
          <img
            src={previewImageUrl || imageUrl}
            alt="Analysis preview"
            className="object-cover w-full h-full"
          />
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <div className="relative aspect-video w-full">
          <img
            src={imageUrl}
            alt="Analysis full view"
            className="object-contain rounded-md w-full h-full"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function AnalysisRow({ analysis }: { analysis: Analysis }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const brands = analysis.result?.brands || [];

  return (
    <>
      <TableRow 
        className={cn(
          "group cursor-pointer hover:bg-muted/50 transition-colors",
          isExpanded && "bg-muted/50"
        )}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCell className="align-top">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
            <ImagePreview imageUrl={analysis.image_url} previewImageUrl={analysis.image_url} />
            <div className="text-xs text-muted-foreground group-hover:text-primary transition-colors">
              {isExpanded ? '↑ Less' : '↓ More'}
            </div>
          </div>
        </TableCell>
        <TableCell className="align-top hidden sm:table-cell">
          <div className={cn(
            "inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium",
            analysis.status === 'completed'
              ? "bg-green-500/10 text-green-600"
              : "bg-yellow-500/10 text-yellow-600"
          )}>
            {analysis.status === 'completed' ? (
              <>
                <Check className="h-4 w-4" />
                <span>Completed</span>
              </>
            ) : (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span className="animate-pulse">Processing...</span>
              </>
            )}
          </div>
        </TableCell>
        <TableCell className="align-top">
          <div className="space-y-3">
            <div className="block sm:hidden">
              <div className={cn(
                "inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium mb-2",
                analysis.status === 'completed'
                  ? "bg-green-500/10 text-green-600"
                  : "bg-yellow-500/10 text-yellow-600"
              )}>
                {analysis.status === 'completed' ? (
                  <>
                    <Check className="h-4 w-4" />
                    <span>Completed</span>
                  </>
                ) : (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span className="animate-pulse">Processing...</span>
                  </>
                )}
              </div>
            </div>
            {brands.length > 0 && (
              <div className="space-y-1">
                <div className="text-xs font-medium text-muted-foreground">
                  Brands ({brands.length})
                </div>
                <div className="flex flex-wrap gap-1">
                  {brands.map((brand, i) => (
                    <Badge key={i} variant="outline" className="text-xs">
                      {brand.name} ({(brand.confidence * 100).toFixed(0)}%)
                    </Badge>
                  ))}
                </div>
              </div>
            )}
          </div>
        </TableCell>
        <TableCell className="align-top text-sm text-muted-foreground hidden sm:table-cell">
          {new Date(analysis.created_at).toLocaleString()}
        </TableCell>
      </TableRow>
      
      {isExpanded && (
        <TableRow className="bg-muted/30">
          <TableCell colSpan={4} className="p-4">
            <Card>
              <CardContent className="pt-6">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <h4 className="font-medium">Detailed Brand Analysis</h4>
                    <div className="grid gap-4 sm:grid-cols-2">
                      {brands.map((brand, index) => (
                        <div 
                          key={index}
                          className="p-4 rounded-lg border bg-card"
                        >
                          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-2">
                            <h5 className="font-medium">{brand.name}</h5>
                            <Badge variant="secondary">
                              {(brand.confidence * 100).toFixed(0)}% confidence
                            </Badge>
                          </div>
                          {brand.description && (
                            <div className="text-sm text-muted-foreground">
                              <p>Description:</p>
                              <p>{brand.description}</p>
                            </div>
                          )}
                          <div className="text-sm text-muted-foreground">
                            <p>Location:</p>
                            <ul className="list-disc pl-5 space-y-1">
                              <li>X: {brand.location.x}</li>
                              <li>Y: {brand.location.y}</li>
                              <li>Width: {brand.location.width}</li>
                              <li>Height: {brand.location.height}</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <details className="text-sm">
                    <summary className="cursor-pointer text-muted-foreground hover:text-foreground">
                      View raw JSON
                    </summary>
                    <pre className="mt-2 whitespace-pre-wrap break-all bg-muted p-4 rounded-lg text-xs overflow-x-auto">
                      {JSON.stringify(analysis.result, null, 2)}
                    </pre>
                  </details>
                </div>
              </CardContent>
            </Card>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function AnalysisTable({ analyses }: { analyses: Analysis[] }) {
  const inProgressAnalyses = (analyses || []).filter(a => a.status !== 'completed');
  const completedAnalyses = (analyses || []).filter(a => a.status === 'completed');

  return (
    <div className="rounded-md border overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[150px]">Image</TableHead>
            <TableHead className="w-[200px] hidden sm:table-cell">Status</TableHead>
            <TableHead>Detected Brands</TableHead>
            <TableHead className="w-[200px] hidden sm:table-cell">Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {inProgressAnalyses.length > 0 && (
            <>
              {inProgressAnalyses.map((analysis) => (
                <AnalysisRow 
                  key={analysis.id || analysis.job_id}
                  analysis={analysis}
                />
              ))}
              <TableRow>
                <TableCell colSpan={4} className="bg-muted/20 py-2 text-center text-sm text-muted-foreground">
                  Completed Analyses
                </TableCell>
              </TableRow>
            </>
          )}
          {completedAnalyses.map((analysis) => (
            <AnalysisRow 
              key={analysis.id || analysis.job_id}
              analysis={analysis}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function usePollAnalyses({ enabled }: { enabled: boolean }) {
  const { start, stop } = usePoll(2000);

  useEffect(() => {
    if (enabled) {
      start();
    } else {
      stop();
    }
  }, [enabled]);
}

function ImageAnalysis({ recent_analyses = [] }: Props) {
  const hasPendingAnalyses = recent_analyses.some(a => a.status !== 'completed');
  usePollAnalyses({ enabled: hasPendingAnalyses });

  return (
    <>
      <Head title="Image Analysis" />
      <div className="p-4 sm:p-6 max-w-7xl mx-auto space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-xl sm:text-2xl font-bold">Image Analysis</h1>
        </div>

        <div className="grid gap-6">
          <Card>
            <CardContent className="pt-6">
              <FileUploader
                accept="image/*"
                maxFiles={5}
              />
            </CardContent>
          </Card>

          <AnalysisTable analyses={recent_analyses} />
        </div>
      </div>
    </>
  );
}

ImageAnalysis.layout = (page: React.ReactNode) => <AuthLayout>{page}</AuthLayout>;

export default ImageAnalysis;
