import { FileIcon, X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface FilePreviewProps {
  file: File & { preview?: string };
  onRemove: (name: string) => void;
}

export function FilePreview({ file, onRemove }: FilePreviewProps) {
  return (
    <div className="flex items-center gap-4 p-4 border rounded-lg bg-muted/50">
      {file.preview ? (
        <img
          src={file.preview}
          alt={file.name}
          className="h-16 w-16 object-cover rounded-lg"
        />
      ) : (
        <FileIcon className="h-8 w-8 text-muted-foreground" />
      )}
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium truncate">{file.name}</p>
        <p className="text-sm text-muted-foreground">
          {(file.size / 1024 / 1024).toFixed(2)} MB
        </p>
      </div>
      <Button
        type="button"
        variant="ghost"
        size="icon"
        className="flex-shrink-0"
        onClick={() => onRemove(file.name)}
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
} 