import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Shield, Image, Scan } from "lucide-react";
import { Head, Link } from '@inertiajs/react';
import { PageProps } from "@/types";

export default function Welcome({ auth }: PageProps) {
    return (
        <>
            <Head title="Welcome to VisionPro" />
            <div className="min-h-screen bg-background">
                {/* Header */}
                <header className="border-b">
                    <div className="container mx-auto px-4 py-4 flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <Shield className="h-6 w-6 text-primary" />
                            <span className="text-xl font-bold">VisionPro</span>
                        </div>
                        <nav>
                            <div className="space-x-2">
                                <Link href={"/login"}>
                                    <Button variant="ghost">Login</Button>
                                </Link>
                                <Link href={"/register"}>
                                    <Button>Register</Button>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="px-4 sm:px-16">
                    {/* Hero Section */}
                    <section className="container mx-auto px-4 py-16 text-center">
                        <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-6">
                            Intelligent Brand & Logo Detection
                        </h1>
                        <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
                            Harness the power of advanced computer vision to automatically detect and analyze brands and logos in any image or video frame.
                        </p>
                        <div className="flex justify-center gap-4">
                            <Link href={"/register"}>
                                <Button size="lg">
                                    Start Analyzing
                                    <Scan className="ml-2 h-4 w-4" />
                                </Button>
                            </Link>
                            <Button size="lg" variant="outline">
                                View Demo
                            </Button>
                        </div>
                    </section>

                    {/* Features */}
                    <section className="container mx-auto px-4 py-16">
                        <div className="grid md:grid-cols-3 gap-6">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Real-time Detection</CardTitle>
                                    <CardDescription>
                                        Instant brand recognition
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Advanced AI algorithms that instantly identify and locate brands and logos in your visual content.
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <CardTitle>Multi-format Analysis</CardTitle>
                                    <CardDescription>
                                        Versatile content processing
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Process images, video frames, and live streams with support for all major file formats and resolutions.
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <CardTitle>Brand Insights</CardTitle>
                                    <CardDescription>
                                        Comprehensive analytics
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Get detailed analytics on brand presence, placement, and visibility across your visual content.
                                </CardContent>
                            </Card>
                        </div>
                    </section>

                    {/* Demo Section */}
                    <section className="container mx-auto px-4 py-16">
                        <div className="bg-muted rounded-lg p-8">
                            <div className="flex items-center justify-center space-x-4 text-center">
                                <Image className="h-8 w-8 text-primary" />
                                <div>
                                    <h3 className="text-2xl font-bold mb-2">Try Our Demo</h3>
                                    <p className="text-muted-foreground">
                                        Upload an image or video to see how our AI instantly detects and analyzes brand presence in real-time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
